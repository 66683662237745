import React from 'react';

import backgroundImage from '../../assets/background/bg_not_found.svg';
import iconImage from '../../assets/bg.png';
import './styles.css';

function NotFound() {
  return (
    <div className='container center'>
      <img style={{
        width: '100%',
        height: 'auto'
      }} src={backgroundImage} alt="Pet Attend | O melhor software para daycare, hotel e banho e tosa" />
      <img className='icon' src={iconImage} alt="Pet Attend | O melhor software para daycare, hotel e banho e tosa" />
    </div>
  )
}

export default NotFound;
