import React from 'react';

import backgroundImage from '../../assets/background/bg_success.svg';
import iconImage from '../../assets/bg.png';
import googlePlayImg from '../../assets/google-play.png';
import appStoreImg from '../../assets/app-store.png';
import './styles.css';

function Success() {
  return (
    <div className='container center'>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ marginBottom: 40 }}>Cadastro efetuado com sucesso!</h1>
        <img style={{
          width: '60%',
          height: 'auto'
        }} src={backgroundImage} alt="Pet Attend | O melhor software para daycare, hotel e banho e tosa" />
        <img className='icon' src={iconImage} alt="Pet Attend | O melhor software para daycare, hotel e banho e tosa" />
        <div className="flex" style={{marginTop: 20}}>
          <a href="https://play.google.com/store/apps/details?id=com.petattend.petattend&hl=en_US" target="_blank" rel="noopener noreferrer">
            <img src={googlePlayImg} width={180} alt="Pet Attend | O melhor software para daycare, hotel e banho e tosa" />
          </a>
          <a href="https://apps.apple.com/br/app/pet-attend/id6447215870" target="_blank" rel="noopener noreferrer">
            <img src={appStoreImg} width={196} alt="Pet Attend | O melhor software para daycare, hotel e banho e tosa" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Success;
